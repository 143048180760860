import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import iot from "../images/snetlabs/iot_.png";
import useStyles from "../styles/styles.js";

const IotDevelopment = () => {
  const classes = useStyles();

  return (
    <>
      <Box id="home" className={classes.heroBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              IOT Application Development
            </h2>

            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
              }}
              className={classes.subtitle1}
            >
              With plethora of Hardware platform varying from Arduino to
              Raspberry Pi, our experts carries hands-on knowledge on various
              sensors or actuators and linking their action to Edge Device or
              Cloud softwares.
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
              }}
              className={classes.subtitle1}
            >
              Our team worked for Smart Toilet, under Smart CIty Projects of
              India, Indian Railways for Break Testing of Boggie, Home
              Automation, Industrial automation(PLC,SCADA Systems), GPS
              navigation systems etc.
            </Typography>

            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={iot}
                alt="My Team"
                style={{ height: "30%", width: "80%" }}
                className={classes.largeImage}
              />
            </div>
            {/* <img src={iot} alt="My Team" className={classes.largeImage} /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default IotDevelopment;