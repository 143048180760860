import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import developServicesMonitoring from "../images/snetlabs/developServicesMonitoring.png";
import useStyles from "../styles/styles.js";

const DevelopServices = () => {
  const classes = useStyles();

  return (
    <>
      <Box id="home" className={classes.mobileAppBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              Develop Scalable & Flexible Services with Easy Monitoring Tools
            </h2>

            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              Whether its fresh development or enhancing exist code, our Cloud &
              Kubernetes(k8) expert ensures that Services developed or migrated
              are massively scalable & flexible and can be monitored easily.
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              Our expertise in Open Source, Docker, Kubernetes(K8), Cloud
              Development, along with UI technologies(ReactJS, Bootstrap etc)
              make us suitable for all your technological needs.
            </Typography>

            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={developServicesMonitoring}
                alt="My Team"
                style={{ height: "30%", width: "70%" }}
                className={classes.largeImage}
              />
            </div>
            {/* <img
              src={developServicesMonitoring}
              style={{ height: "300px" }}
              alt="My Team"
              className={classes.largeImage}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default DevelopServices;