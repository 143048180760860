import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import cloudunify from "../images/snetlabs/cloudunify_.png";
import useStyles from "../styles/styles.js";

const CloudUnify = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.mobileAppBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6} sm={12}>
            <h2
              className={classes.heading1}
            >
              Cloud Unify
            </h2>

            <Typography
              variant="h4"
              sx={{ mt: 1 }}
              style={{
                padding: "0px",
                fontSize: "22px",
                alignItems: "center",
                fontWeight: "bold",
              }}
              className={classes.subtitle1}
            >
              Easying Multi-Cloud Access Management from a Unified Dashboard
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "20px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              With Cloud Unify, you can manage access of your developer teams,
              contractors , freelancer etc from a Unified Dashboard in a secure
              way. Off-boarding & on-boarding of a team member remains a plain
              cake walk.
            </Typography>

            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={cloudunify}
                alt="My Team"
                style={{ height: "30%", width: "80%" }}
                className={classes.largeImage}
              />
              <a
                href="https://www.gounifycloud.com/"
                target="blank_"
                style={{
                  textTransform: "none",
                  width: "200px",
                  marginTop: "20px",
                }}
                className={classes.customBtn}
              >
                Click to know more
              </a>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CloudUnify;