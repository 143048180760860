import { useState, useRef } from "react";
import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import useStyles from "../styles/styles.js";
import SendEmail from "../api/SendEmail.jsx";
import ReCAPTCHA from "react-google-recaptcha";
import {phone} from 'phone';
import "../index.css";

const ContactUs = (props) => {

  const recaptchaRef = useRef()

  let [name, setName] = useState('')
  let [email, setEmail] = useState('')
  let [companyName, setCompanyName] = useState('')
  let [countryCode, setCountryCode] = useState('')
  let [mobile, setMobile] = useState('')
  let [desc, setDesc] = useState('')
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  const [showMessage, setShowMessage] = useState(true);
  const [message, setMessage] = useState("");
  const [statusCode, setStatusCode] = useState("250");
  const [isDisabled, setIsDisabled] = useState(false);

  const classes = useStyles();
  const checkCaptcha = () => {
    console.log(isCaptchaSuccessful);
    setIsCaptchaSuccess(true);
    setMessage("");
    showMessage(false);
  };
  const sendEmailResponse = async (data) => await SendEmail(data);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isCaptchaSuccessful) {
      setShowMessage(true);
      setMessage("Are you human ?");
      setStatusCode(100);
      setTimeout(() => {
        setShowMessage(false);
        setMessage("");
        setStatusCode("");
      }, 3000);
      return;
    }
    name = name.trim();
    companyName = companyName.trim();
    email = email.trim();
    countryCode = countryCode.trim();
    mobile = mobile.trim();
    desc = desc.trim();
    if(
      name !== '' 
    && companyName !== '' 
    && countryCode !== ''
    && mobile !== ''
    && desc !== '' 
    ){
      if(countryCode.length<=3){
      countryCode = '+'.concat(countryCode);
       console.log(countryCode, 'code');
       const verify = phone(countryCode.concat(mobile));
       console.log(verify.isValid, 'verify');
       if(verify.isValid){
        
        const data = {
          from: "SNeT Labs Pvt Ltd",
          username: name,
          country: "India",
          companyName: companyName,
          countryCode: countryCode,
          companyEmail: email,
          mobileNumber: mobile,
          description: desc,
        };
        setIsDisabled(true);
        console.log('data', data);  
        const apiResponse = sendEmailResponse(data);
        apiResponse.then((result) => {
        setShowMessage(true);
        if (result.data === "250") {
        setStatusCode("250");
        setMessage(
          "Thanks for Contacting Us. Our team will get back to you soon."
          );
        } else {
          setStatusCode("100");
          setMessage(
          "Server is busy. We are not able to accept you email.Please try again"
          );
        }
        setTimeout(() => {
        setShowMessage(false);
        setStatusCode("");
        setMessage("");
        setIsDisabled(false);
        setName('');
        setCompanyName('');
        setEmail('');
        setCountryCode('');
        setMobile('');
        setDesc('');
        recaptchaRef.current.reset();
        }, 4000);
      });
    }
    else{
    setShowMessage(true);
    setMessage("Mobile Number is not valid!!!");
   console.log('Mobile Number is not valid!!!')
    }
  }
  else{
    console.log(`Country Code is not Valid!!!`);
    setShowMessage(true);
    setMessage("Country Code is not Valid!!!");
  }
  }
  else{
  console.log(`error `);
  setShowMessage(true);
  setMessage("Please Fill all the Fields correctly!!!");
  }
  };
  return (
    <>
      <Box id="contact" className={classes.ContactHeroBox}>
        <Grid container className={classes.contactGridContainer}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              variant="h2"
              style={{ padding: "0px", color: "white" }}
              fontWeight={700}
              className="text-white"
            >
              Get In Touch{" "}
            </Typography>
            <Divider
              style={{
                backgroundColor: "white",
                width: "25%",
              }}
            />
            <p
              style={{
                marginTop: "10px",
                marginBottom: "30px",
                color: "white",
              }}
            >
              Please fill out the form below to send us an email and we will get
              back to you.
            </p>

            <form name="sentMessage" validate onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      id="name"
                      style={{ borderRadius: "5px" }}
                      name="name"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      required
                      onChange={(e)=>setName(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      style={{ borderRadius: "5px" }}
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      required
                      onChange={(e)=>setEmail(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-group">
                    <input
                      type="text"
                      id="CompanyName"
                      style={{ borderRadius: "5px" }}
                      name="companyName"
                      className="form-control"
                      placeholder="Company Name"
                      value={companyName}
                      required
                      onChange={(e)=>setCompanyName(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <input
                      type="number"
                      id="countryCode"
                      name="countryCode"
                      style={{ borderRadius: "5px", fontSize: '16px', paddingLeft: '6px'
                     }}
                      className="form-control hide-spinner"
                      placeholder="Country Code"
                      value={countryCode}
                      required
                      onChange={(e)=>setCountryCode(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <input
                      type="number"
                      id="mobile"
                      name="mobile"
                      style={{ borderRadius: "5px" }}
                      className="form-control hide-spinner"
                      placeholder="Mobile"
                      value={mobile}
                      required
                      onChange={(e)=>setMobile(e.target.value)}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="4"
                  placeholder="Message"
                  style={{ borderRadius: "5px" }}
                  required
                  value={desc}
                  onChange={(e)=>setDesc(e.target.value)}
                ></textarea>
                <p className="help-block text-danger"></p>
              </div>
              <div className="captcha">
                <ReCAPTCHA
                  className="captcha"
                  sitekey="6LczCsImAAAAALBrNm2G8_atqf61-aLtJtBxA0r3"
                  onChange={checkCaptcha}
                  ref={recaptchaRef}
                />
              </div>

              {showMessage ? (
                <>
                  {statusCode === "250" ? (
                    <p
                      style={{
                        color: "#00ff3a",
                        fontSize: "18px",
                      }}
                    >
                      {message}
                    </p>
                  ) : (
                    <p style={{ color: "yellow", fontSize: "18px" }}>
                      {message}
                    </p>
                  )}
                </>
              ) : null}

              <button
                type="submit"
                disabled={isDisabled}
                className="btn btn-custom btn-lg"
              >
                {isDisabled ? "Sending..." : "Send Message"}
              </button>
            </form>
          </Grid>
          <Grid item md={4} sx={12}>
            <div className="col-md-offset-1 contact-info">
              <Typography
                variant="h4"
                style={{ color: "white", fontWeight: "bold" }}
              >
                Contact Info
              </Typography>
              <p
                style={{
                  marginTop: "10px",
                }}
              >
                <span style={{ color: "white" }}>
                  <i
                    className="fa fa-map-marker"
                    style={{ color: "white" }}
                  ></i>{" "}
                  &nbsp; Address
                </span>
              </p>
              <p
                style={{
                  marginBottom: "30px",
                  color: "white",
                }}
              >
                SNeT Labs, Serenia, IHDP Business Park, Plot No. 7, Sector 127, Noida, Uttar Pradesh 201303


              </p>

              <p
                style={{
                  marginTop: "10px",
                }}
              >
                <span style={{ color: "white" }}>
                  <i className="fa fa-envelope-o"></i> &nbsp; Email
                </span>
              </p>
              <p
                className="email-id"
                style={{
                  marginBottom: "30px",
                  color:"white"
                }}
              >
                <EmailLink email="contact@snetlabs.com" />
              </p>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUs;

const EmailLink = ({ email }) => {
  const classes = useStyles();

  const handleEmailClick = () => {
    const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=CllgCKHQdWBgBBhJwfSDZfZftnzwpznXwLQkPGGBcfsRNXdpdhXhQldHdDzkJVqblcTQrjLxjlq`;
    window.open(gmailUrl, "_blank");
  };

  return (
    <span
      className={classes.emailId}
      onClick={handleEmailClick}
      style={{ textDecoration: "underline", cursor: "pointer" }}
    >
      {email}
    </span>
  );
};