import {  Box , Stack, Button,CardActions} from "@mui/material";
import { useEffect, useState } from "react";
import useStyles from "../styles/styles";
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import axios from "axios";


export const BlogContainer=()=>{  

    const navigate = useNavigate()
    const classes = useStyles();
  const [blogList, setBlogList] = useState([]);
  const [error, setError] = useState(null)
  

  const handleRoute=(link)=>{
    link.includes('http') ? window.open(link, '_blank') 
    : navigate(link)
  }

  useEffect(()=>{
    const fetchList = async()=>{
      try{
      const response = await axios.get('https://snetlabs-blogs-list.s3.ap-northeast-1.amazonaws.com/snetlabs_blog_list.json');
      if(response.status === 200 && response.data.length > 0){
        setBlogList(response.data);
        // console.log(response.data)
        setError(null);
      }
      else{
        setError('Blogs not Available.');
      }
      }
      catch(e){
        setError('Blogs not Available.');
      };
    };
    fetchList()
  },[]);

    return (
        <>
        <Box id="blog" className={`${classes.heroBox} ${classes.blogContainer}`}>
          {error ? 
          <Typography variant="h3" component='h1' sx={{ color: 'white' }}>
            {error}
          </Typography>
          :
          <>
          <Typography variant="h2" component='h1' sx={{ color: 'white' }}>Blogs</Typography>
          <Stack className={classes.cards} justifyContent="center" alignItems="center"
           direction={{ xs: 'column', sm: 'row' }}>
         {blogList.map((blogCard , index)=>
         <Card key ={blogCard.title} className={classes.card} onClick={()=>handleRoute(blogCard.route)}>
         <CardActionArea>
         <CardMedia
         component="img"
         height="130"
         image={blogCard.img}
       />
       <CardContent>
         <Typography gutterBottom variant="h5" component="h1" sx={{mb : 1,
          fontSize: blogCard.title.length > 25 ? '16px' : '17px'}}>
          {blogCard.title.length>24 ? `${blogCard.title.slice(0,25)}...` : blogCard.title}
         </Typography>
         <Stack direction='column' spacing={0}  >
         <Typography variant="h6" component='p' color="text.secondary">
          {blogCard.description.length > 55 ? `${blogCard.description.slice(0,55)}...` : blogCard.description}
         </Typography>
         <Typography variant="h6" component='p' style={{display : 'flex',
        justifyContent : 'flex-end', gap: '3px',}}>
          <span style ={{color : 'gray'}}>By</span><i 
          style={{fontWeight : '600'}}>{blogCard.name}</i>
         </Typography>
         </Stack>
       </CardContent>
       <CardActions sx={{ ml : 2, p: 0}}>
        <Button size="small" variant="contained" sx={{ fontSize: '1.2rem' }}
        onClick={()=>window.open(blogCard.route, '_blank')}
        >Read More...</Button>
      </CardActions >
     </CardActionArea>
   </Card>
         )};   
    </Stack>
    </>
};
        </Box>
        </>
    )
};