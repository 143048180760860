import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logodesktop from "../images/snetlabs/snetlabs-icon_.png";
import logomob from "../images/snetlabs/snetlabs-icon-mob.png";
import "../App.css";

export const Navigation = (props) => {
  const isMobileView = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const navigate = useNavigate();
  const logo = isMobileView ? logomob : logodesktop;

  const deriveActiveLink = () => {
    if (location.pathname === "/blogs") return "blogs";
    else if (location.hash === "#product") return "products";
    else if (location.hash === "#service") return "services";
    else if (location.hash === "#contact") return "contacts";
    return "";
  };

  const [activeLink, setActiveLink] = useState(deriveActiveLink());

  useEffect(() => {
    setActiveLink(deriveActiveLink());
  }, [location.pathname, location.hash]);

  const handleLinkClick = async(e, target) => {
    e.preventDefault();
    setActiveLink(target);
    if(target !== 'blogs'){
      await navigate(`/`)
    };
    await navigate(`/#${target}`)
    document.getElementById(target)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top navbar-custom">
      <div className="container">
        <div className="navbar-header" style={{ marginRight: "50px" }}>
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a href="/#home">
            <Box
              component="img"
              sx={{ height: 50 }}
              alt="The house from the offer."
              src={logo}
              onClick={(e) => handleLinkClick(e, 'home')}
            />
          </a>
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            <li className={activeLink === "products" ? "active" : ""}>
              <Link to="/#product" className="page-scroll custom-link" onClick={(e) => handleLinkClick(e, 'product')}>
                Products
              </Link>
            </li>
            <li className={activeLink === "services" ? "active" : ""}>
              <Link to="/#service" className="page-scroll custom-link" onClick={(e) => handleLinkClick(e, 'service')}>
                Services
              </Link>
            </li>
            <li className={activeLink === "blogs" ? "active" : ""}>
              <Link to="/blogs" className="page-scroll custom-link">
                Blogs
              </Link>
            </li>
            <li className={activeLink === "contacts" ? "active" : ""}>
              <Link to="/#contact" className="page-scroll custom-link" onClick={(e) => handleLinkClick(e, 'contact')}>
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
