import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import devops from "../images/snetlabs/devops_.png";
import useStyles from "../styles/styles.js";

const Devops = () => {
  const classes = useStyles();

  return (
    <>
      <Box id="home" className={classes.heroBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              DevOps & L3/L2/L1 Support
            </h2>

            <Typography
              variant="h4"
              sx={{ mt: 3 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              When you are sleeping our team ensure that your services are up
              and running, without affecting your customer experience.
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              Deployment of softwares and other developer operations are ensure
              by DevOps Team have decade of experience with automation tools
              like Rancher, Cloudformation(AWS), Terraform, Ansible etc.
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              Based your organization need, we offer to provide support all
              levels like L1, L2 or L3.
            </Typography>
            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={devops}
                alt="My Team"
                style={{ height: "30%", width: "70%" }}
                className={classes.largeImage}
              />
            </div>
            {/* <img
              src={devops}
              className={classes.largeImage}
              style={{ height: "300px" }}
              alt="My Team"
            /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Devops;