import '../index.css'
import { Typography } from "@mui/material";


export const Footer=()=>{
    return(
        <div id="footer">
        <div className="container text-center ">
          <Typography
            variant="h6"
            color="black"
            style={{ fontWeight: "bold", fontSize: "16px" , padding : '20px 0px' }}
          >
            Copyright &copy; 2023 SNeT Labs Pvt Ltd.&reg;
          </Typography>
        </div>
      </div>
    )
}