import React, { useState } from "react";
import { Navigation } from "./components/Navigation";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import "./index.css";
import { Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BlogContainer } from "./components/BlogContainer";
import { HomeCollection } from "./components/HomeCollection";
import { Footer } from "./components/Footer";
export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});
const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

const App = () => {

  return (
    <ThemeProvider theme={theme}>
      <Navigation />
    <Routes>
      {/* <Slider /> */}
      <Route path="/" element={<HomeCollection/>}/>
      <Route path="/blogs" element={<BlogContainer/>}/>
    </Routes>
    <Footer/>
    </ThemeProvider>
  );
};

export default App;
