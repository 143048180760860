import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import intraas from "../images/snetlabs/intraas.png";
import useStyles from "../styles/styles.js";

const Intraas = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.heroBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              IntraaS{" "}
            </h2>

            <Typography
              variant="h4"
              sx={{ mt: 1 }}
              style={{
                padding: "0px",
                fontSize: "22px",
                alignItems: "center",
                fontWeight: "bold",
              }}
              className={classes.subtitle1}
            >
              Setup Your Organization{" "}
              <span style={{ color: "red" }}>INTRANET</span> On Cloud
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 4 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
              }}
              className={classes.subtitle1}
            >
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "28px",
                }}
              >
                {" "}
                With IntraaS{" "}
              </span>
              (Intranet As A Service), you can setup your organization on cloud,
              unifying networks, machines & apps access from single Unified
              Dashboard, no matter where they are actually placed i.e. on any
              Cloud or on premise.
            </Typography>

            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5}>
            <img src={intraas} alt="My Team" className={classes.largeImage} />
            <div style={{ marginTop: "25px", textAlign: "center" }}>
              <a
                href="https://www.intraas.com/"
                target="blank_"
                style={{ textTransform: "none" }}
                className={classes.customBtn}
              >
                Click to know more
              </a>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Intraas;