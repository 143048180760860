import axios from "axios";

const SendEmail = async (data) => {
  try {
    const apiCall = await axios.post(
      `https://thgc03qklj.execute-api.us-east-2.amazonaws.com/mail`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "824yQUYv1f3jGGDbIue3J3IWKqs4acpY3WXubEDN",
        },
      }
    );
    return apiCall;
  } catch (error) {
    if (error.response) {
      return error.response;
    }
    if (error.request) {
      return error;
    }
    return error;
  }
};

export default SendEmail;
