import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import myteam from "../images/snetlabs/home_.png";
import useStyles from "../styles/styles.js";
import makeInIndia from "../images/snetlabs/make-in-india.png";
import startUpIndia from "../images/snetlabs/startup-india.png";
import msme from "../images/snetlabs/msme.png";
const Homepage = () => {
  const classes = useStyles();
  const isMobileView = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint according to your mobile view
  const typographyVariantTitle = isMobileView
    ? { fontWeight: "bold", fontSize: "2.2rem" }
    : { fontWeight: "bold", fontSize: "5rem" }; // Customize the typography variant based on the mobile view

  const typographyVariantSubtitle = isMobileView ? "h6" : "h3"; // Customize the typography variant based on the mobile view // Customize the typography variant based on the mobile view

  return (
    <>
      <Box id="home" className={classes.mobileAppBox}>
        <Grid
          container
          spacing={6}
          px={2}
          className={classes.gridContainerHome}
        >
          <Grid item xs={12} md={6} sx={12} sm={12}>
            <h2 style={typographyVariantTitle} className={classes.heading1}>
              Elevate your enterprise's AI capabilities to new heights.
            </h2>
            <Typography
              className={classes.subtitle1}
              variant="body1"
              sx={{ mt: 3 }}
              style={{ padding: "0px", fontSize: "18px" }}
            >
              Harness the power of digital transformation by collaborating with
              our experts to expand your business with AI models tailored to
              your needs.
            </Typography>
            <Typography
              variant={typographyVariantSubtitle}
              sx={{ mt: 5 }}
              style={{ fontWeight: "bold", textTransform: "none" }}
              className={classes.subtitle1}
            >
              Our business model & processes are tailored to suit organizations
              of all sizes,
              <span style={{ color: "red" }}> including Startups.</span>
            </Typography>

            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Discuss Your Project
              </a>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} sm={12}>
            <img
              src={myteam}
              alt="My Team"
              style={{ textAlign: "center" }}
              className={classes.homeImage}
            />
          </Grid>
        </Grid>
      </Box>

      <Box px={5} className={classes.heroBox1}>
        <Grid
          container
          className={classes.gridContainer}
          style={{ paddingTop: "0px", paddingBottom: "0px" }}
        >
          <Grid item xs={12} sx={12} md={4}>
            <Grid container alignContent="center" mt={8} textAlign="center">
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="h4" fontWeight={700} mb={0}>
                  100+
                </Typography>

                <Typography
                  variant="h6"
                  mt={0}
                  style={{
                    padding: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Projects
                </Typography>
              </Grid>

              <Grid item md={1} xs={1} sm={6}>
                <Typography variant="h4" fontWeight={700} mb={0}>
                  20+
                </Typography>

                <Typography
                  variant="h6"
                  mt={0}
                  style={{
                    padding: "0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  Technologies
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2} sx={12} md={8}>
            <Grid container>
              <Grid item md={4} xs={12} sm={12}>
                <img
                  src={makeInIndia}
                  alt="My Team"
                  // style={{ height: "150px" }}
                  style={{ width: "80%", height: "70%" }}
                  className={classes.largeImage}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={12}>
                <img
                  src={startUpIndia}
                  alt="My Team"
                  style={{ width: "80%", height: "70%" }}
                  className={classes.largeImage}
                />
              </Grid>
              <Grid item md={4} xs={12} sm={12}>
                <img
                  src={msme}
                  alt="My Team"
                  style={{ width: "80%", height: "70%" }}
                  className={classes.largeImage}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Homepage;