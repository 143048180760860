import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import mobileAppImage from "../images/snetlabs/mobile-applications.png";
import useStyles from "../styles/styles.js";

const MobileApplicationsDevelopment = () => {
  const classes = useStyles();

  return (
    <>
      <Box id="home" className={classes.mobileAppBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              Mobile Applications Development
            </h2>
            <Typography
              variant="body1"
              sx={{ mt: 2 }}
              style={{ padding: "0px", fontSize: "18px" }}
              className={classes.subtitle1}
            >
              Whether its Native App Development or App Development with Cross
              Platform SDK(Flutter, React Native), our team not only makes great
              UI with enhanced User Experiences, but also integrate your Apps
              with backend.
            </Typography>
            <Typography
              variant="h6"
              sx={{ mt: 4 }}
              style={{ color: "black", fontSize: "18px" }}
              className={classes.subtitle1}
            >
              We also hold expertise to communicate apps with{" "}
              <span
                style={{ color: "black", fontSize: "16px", fontWeight: "bold" }}
              >
                BLE devices{" "}
              </span>
              &{" "}
              <span
                style={{ color: "black", fontSize: "16px", fontWeight: "bold" }}
              >
                NFC devices.{" "}
              </span>
            </Typography>
            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={mobileAppImage}
                alt="My Team"
                style={{ height: "20%", width: "80%" }}
                className={classes.largeImage}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MobileApplicationsDevelopment;