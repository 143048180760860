import { Reviews } from "./Reviews"
import CloudSoftwareDevelopment from "./CloudSoftwareDevelopment"
import CloudUnify from "./CloudUnify"
import DevelopServices from "./DevelopServices"
import Devops from "./Devops"
import Homepage from "./Homepage"
import Intraas from "./Intraas"
import IotDevelopment from "./IotDevelopment"
import Labsense from "./Labsense"
import MobileApplicationsDevelopment from "./MobileApplicationsDevelopment"
import ContactUs from "./ContactUs"


export const HomeCollection=()=>{
    return(
        <>
            <Homepage/>
            <h3
            id="product"
            style={{ marginBottom: "20px", color: "black" }}
            className="text-center"
            >
            Products
            </h3>
            <CloudUnify/>
            <Intraas/>
            <h3
            id="service"
            style={{ marginBottom: "20px", color: "black" }}
            className="text-center"
            >
            Services
            </h3>
            <Labsense/>
            <MobileApplicationsDevelopment/>
            <IotDevelopment/>
            <CloudSoftwareDevelopment/>
            <Devops/>
            <DevelopServices/>
            <Reviews/>
            <ContactUs/>
        </>
    )
}