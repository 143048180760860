import { makeStyles } from "@mui/styles";

const styles = (theme) => {
  return {
    toolBar: {
      height: "10vh",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px",
      backgroundColor: "white",
    },
    logo: {
      color: "blue",
      cursor: "pointer",
    },
    link: {
      color: "#000",
    },
    menuIcon: {
      color: "#000",
    },
    formContainer: {
      flexGrow: 1,
      padding: "10px",
      maxWidth: "700px",
      margin: "30px auto",
      [theme.breakpoints.between("xs", "sm")]: {
        width: "100%",
      },
    },
    form: {
      marginTop: "30px",
    },
    formHeading: {
      textAlign: "center",
    },
    heroBox: {
      width: "100%",
      display: "flex",
      minHeight: "70vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f3f3f3ff",
    },

    heroBox1: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "#f3f3f3ff",
    },

    ContactHeroBox: {
      width: "100%",
      display: "flex",
      minHeight: "80vh",
      alignItems: "center",
      justifyContent: "center",

      backgroundImage:
        "linear-gradient(to bottom, #19488e, #005e9b, #0072a0, #00849f, #11959b);",
    },

    PricingheroBox: {
      width: "100%",
      display: "flex",
      minHeight: "20vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundImage:
        "linear-gradient(to top, #1600ff, #2415f6, #2e21ed, #3629e4, #3d30db, #3b32c7, #3932b3, #38329f, #312d7c, #2b275a, #232139, #1a1a1a)",
    },
    gridContainer: {
      display: "flex",
      // alignItems: "center",
      maxWidth: "1800px",
      padding: "50px",
    },

    gridContainerHome: {
      display: "flex",
      alignItems: "center",
      maxWidth: "100%",
      padding: "50px",
    },

    contactGridContainer: {
      display: "flex",
      alignItems: "center",
      maxWidth: "90vw",
      padding: "50px",
    },
    aboutUsContainer: {
      width: "100%",
      display: "flex",
      minHeight: "400px",
      alignItems: "center",
      justifyContent: "center",
      margin: "30px 0px 50px 0px",
    },
    aboutUsSubtitle: {
      opacity: "0.7",
      paddingBottom: "30px",
      fontSize: "20px",
    },
    title: {
      paddingBottom: "15px",
      color: "black",
    },
    subtitle: {
      opacity: "0.8",
      color: "black",
    },
    subtitle1: {
      opacity: "1",
      color: "black",
    },
    largeImage: {
      width: "100%",
    },
    homeImage: {
      width: "100%",
      textAlign: "center",
      display: "block",
      justifyContent: "center",
      alignItems: "center",
      margin: "auto",
    },

    sectionGridContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minHeight: "500px",
    },
    sectionGridItem: {
      backgroundColor: "#0350687a",
      textAlign: "center",
      padding: "30px",
      width: "150px !important",
      borderRadius: "10px",
      margin: "50px !important",
    },
    inputField: {
      marginBottom: "20px !important",
    },
    textArea: {
      width: "100%",
      marginBottom: "20px",
      fontSize: "16px",
      padding: "10px",
    },
    footerContainer: {
      display: "flex",
      alignItems: "center",
      minHeight: "10vh",
      padding: "20px",
      justifyContent: "center",
      backgroundColor: "#f2f0f1",
      flexDirection: "column",
    },
    footerText: {
      paddingBottom: "10px",
    },
    footerDate: {
      opacity: "0.4",
    },
    testimonialCard: {
      backgroundColor: "#fff",
      padding: "10px",
      minHeight: "200px",
      display: "flex",
      alignItems: "center",
    },
    testimonialStatement: {
      paddingBottom: "25px",
    },
    avatar: {
      marginRight: "10px",
    },
    testimonialPosition: {
      fontSize: "14px",
      opacity: "0.6",
    },
    customBtn: {
      "&:hover": {
        borderRadius: 10,
        backgroundColor: "#14bec",
      },

      textTransform: "uppercase",
      color: "white !important",
      backgroundColor: "#348f9f",
      padding: "12px",
      width: "100%",
      borderRadius: 5,
      fontSize: "12px",
      fontWeight: "700",
      transition: "all 0.5s linear",
      border: 0,
    },

    mobileAppBox: {
      paddingTop: "50px",
      width: "100%",
      display: "flex",
      minHeight: "60vh",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
    },
    mobileAppBoxImage1: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    largeImage1: {
      width: "50%",
      height: "60%",
    },
    heading1: {
      color: "#00415f",
      textTransform: "none",
    },
    emailId: {
      "&:hover": {
        color: "black",
      },
      transition: "all 0.2s linear",
    },
    carousels: {
      width : '60%',
      margin : '30px 0px',
      [theme.breakpoints.down('xl')]: { 
        width : '60%' ,
    },
  
    [theme.breakpoints.down('lg')]: { 
      width : '70%',
    },
  
    [theme.breakpoints.down('md')]: { 
      width : '80%',
      padding: '10px'
    },
  
    [theme.breakpoints.down('sm')]: { 
      width : '90%',
      padding: '5px',
    },
  
    [theme.breakpoints.down('xs')]: { 
      width : '95%',
      padding: '5px',
    },
    },
    cardData : {
      minHeight: '400px',
      maxWidth : '80%',
      display: 'flex',
      alignItems: 'center',
      margin: 'auto',
      color : 'white',
      borderRadius: '15px',
      padding: '10px 30px',
      transition : 'all .2s linear',
      backgroundImage:
      "linear-gradient(to bottom, #19488e, #005e9b, #0072a0, #00849f, #11959b);",
      
      '&:hover' : {
        transform : 'Scale(1.02)'
      },
      [theme.breakpoints.down('md')]: { 
        minHeight : '350px',
        padding: '10px',
      },
      [theme.breakpoints.down('sm')]: { 
        maxWidth : '85%',
        minHeight : '370px',
        padding: '10px',
      },
      
      [theme.breakpoints.down('xs')]: { 
        maxWidth : '85%',
        minHeight : '350px',
        padding: '10px',
      },
  },

  cardContent : {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    gap : '10px 0px',
  },
  titleHead : {
    display : 'flex',
    justifyContent : 'space-between',
  },
  cardName : {
    fontWeight : 500,
    fontSize : '30px',

    [theme.breakpoints.down('md')]: { 
      fontSize : '25px',
    },

    [theme.breakpoints.down('sm')]: { 
      fontSize : '20px',

    },
  
    [theme.breakpoints.down('xs')]: { 
      fontSize : '15px',

    },
  },
  designation : {
    fontSize : '20px',
    fontWeight : 300,
    [theme.breakpoints.down('md')]: { 
      fontSize : '14px',
    },

    [theme.breakpoints.down('sm')]: { 
      fontSize : '12px',

    },
  
    [theme.breakpoints.down('xs')]: { 
      fontSize : '10px',

    },
  },

  content : {
    fontSize: '16px',

    [theme.breakpoints.down('md')]: { 
      fontSize : '14px',
    },

    [theme.breakpoints.down('sm')]: { 
      fontSize : '12px',

    },
  
    [theme.breakpoints.down('xs')]: { 
      fontSize : '10px',

    },
  },
  customCard : {
    maxWidth : '95%',
    [theme.breakpoints.down('sm')]: { 
      padding: '0px',
    },
    
    [theme.breakpoints.down('xs')]: { 
      padding: '0px',
    },
  }, 
  blogContainer : {
    display : 'flex',
    minHeight: '84.5vh',
    maxHeight: 'max-content',
    width : '100%',
    flexDirection: 'column',
    padding: '20px 0px',
    justifyContent : 'space-evenly',
    gap : '20px',
    alignItems: 'center',
    backgroundImage:
    "linear-gradient(to bottom, #19488e, #005e9b, #0072a0, #00849f, #11959b);",
  },
  cards : {
    display : 'flex',
    flexWrap : 'wrap',
    justifyContent : 'center',
    gap : '30px',
    padding : '10px 50px'
  },
  card: {
    width : '250px',
    minHeight : '300px',
    maxHeight : '300px',
    transition : 'all .2s linear',
    borderRadius : '20px',
    '&:hover': {
      transform: 'scale(1.05)'
    }, 
    backgroundColor: '#F9F3CC',
    '@media (max-width: 280px)': {
      maxWidth: '250px',
      maxHeight : '380px',
  },
  }, 
  };
};

const useStyles = makeStyles(styles);
export default useStyles;