import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import cloudsoftware from "../images/snetlabs/cloudsoftware_.png";
import useStyles from "../styles/styles.js";

const ai_detail = [
  {
    title: "Cloud Application Development",
    desc: "Develop applications on the cloud that enable universal access across all devices, at any time, while ensuring real-time data synchronization.",
  },
  {
    title: "Cloud Consultancy",
    desc: "When viewed from a business perspective, the cloud can present a multifaceted view. Allow us to assist you in selecting the ideal cloud strategy tailored to your needs.",
  },
  {
    title: "Cloud Migration",
    desc: "We develop strategies after considering factors like performance, cost & security. Our team has extensive experience with cloud migration, be it from legacy app to cloud, or moving apps between cloud platforms.",
  },
  {
    title: "Cloud Security",
    desc: "Our utmost priority is to safeguard the data stored in the cloud, implementing robust security measures to protect against cyber attacks and unauthorized access.",
  },
  {
    title: "Cloud Managed Services",
    desc: "Through our cloud-first consulting approach, we take charge of managing your public and multi-cloud environments, including deploying them on platforms such as AWS and Azure. Additionally, we provide assistance in designing, building, and optimizing applications to ensure optimal performance in the cloud.",
  },
];

const CloudSoftwareDevelopment = () => {
  const classes = useStyles();
  const isMobileView = useMediaQuery("(max-width:600px)"); // Adjust the breakpoint according to your mobile view

  return (
    <>
      <Box id="home" className={classes.mobileAppBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
          <h1
              style={
                isMobileView
                  ? {
                      fontWeight: "bold",
                      fontSize: "36px",
                    }
                  : { fontWeight: "bold", fontSize: "42px" }
              }
              className={classes.heading1}
            >
              Cloud Software Development
            </h1>
            {ai_detail.map((value, index) => {
              return (
                <div key={index}>
                  <Typography
                    variant="h4"
                    sx={{ mt: 4 }}
                    style={{
                      padding: "0px",
                      fontSize: "18px",
                      alignItems: "center",
                      fontWeight: "bold",
                    }}
                    className={classes.subtitle1}
                  >
                    {value.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 2 }}
                    style={{
                      padding: "0px",
                      fontSize: "16px",
                      alignItems: "center",
                    }}
                    className={classes.subtitle}
                  >
                    {value.desc}
                  </Typography>
                </div>
              );
            })}
            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5} xs={12}>
            <div
              style={{
                marginTop: "25px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={cloudsoftware}
                alt="My Team"
                style={{ height: "30%", width: "80%" }}
                className={classes.largeImage}
              />
            </div>
            {/* <img
              src={cloudsoftware}
              alt="My Team"
              className={classes.largeImage}
            /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CloudSoftwareDevelopment;