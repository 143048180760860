import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import labsense from "../images/snetlabs/labsense.gif";
import useStyles from "../styles/styles.js";

const ai_detail = [
  {
    title: "Data Collection",
    desc: "To make your job easy, we let you sit back and relax and do the data collection on your behalf",
  },
  {
    title: "Data Annotation",
    desc: "Our subject matter experts ensure accurate labelling of data for various domains like healthcare, surveillance, retail, drone & aerial imagery, restaurants etc.",
  },
  {
    title: "ML Models Training",
    desc: "Delegate model training to our AI experts equipped with best in class tools and GPU machines augmented by our best practices.",
  },
  {
    title: "ML Models Deployment",
    desc: "We support deployment of models to Edge Devices or Cloud Servers as per your business need.",
  },
  {
    title: "Custom Software Development",
    desc: "Our software expertise, whether its development at Edge Side or Cloud side, ensures that your ML models comes into life without facing technical challenges by you.",
  },
];

const Labsense = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.heroBox}>
        <Grid
          container
          spacing={6}
          className={classes.gridContainer}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={6}>
            <h2
              className={classes.heading1}
            >
              labsense.ai
            </h2>

            <Typography
              variant="h4"
              sx={{ mt: 1 }}
              style={{
                padding: "0px",
                fontSize: "18px",
                alignItems: "center",
                fontWeight: "bold",
              }}
              className={classes.subtitle1}
            >
              Making AI Easy For Businesses
            </Typography>

            {ai_detail.map((value, index) => {
              return (
                <div key={index}>
                  <Typography
                    mt={3}
                    variant="h6"
                    fontWeight="bold"
                    className={classes.subtitle1}
                    style={{ fontSize: "16px" }}
                  >
                    {value.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ mt: 1 }}
                    style={{
                      padding: "0px",
                      fontSize: "16px",
                      alignItems: "center",
                    }}
                    className={classes.subtitle1}
                  >
                    {value.desc}
                  </Typography>
                </div>
              );
            })}
            <Box sx={{ mt: 8 }}>
              <a href="#contact" className={classes.customBtn}>
                Get Started
              </a>
            </Box>
          </Grid>
          <Grid item md={5}>
            <div
              style={{
                textAlign: "center",

                flexDirection: "column",
              }}
            >
              <img
                src={labsense}
                alt="My Team"
                className={classes.largeImage}
              />
              <Box sx={{ mt: 6 }}>
                <a
                  href="https://labsense.ai/home"
                  target="blank_"
                  style={{ textTransform: "none" }}
                  className={classes.customBtn}
                >
                  Click to know more
                </a>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Labsense;