import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useStyles from '../styles/styles'
import Carousel from 'react-material-ui-carousel';
import Stack from '@mui/material/Stack';



const allReviews = [
    {
    name: 'Mukesh Gupta',
    content : `We wanted to do R&D project augmented by AI. We knew Neeraj's experience & their team expertise very well. We discussed the project to identify electric spark generated between pantograph of Rail engine and electric wire. In just two weeks of time, labSense team was ready with demo with accuracy of more than 80% as proof of concept. Now we are in discussion with Indian Railways. We see SNeT as our valuable Software R&D partner.`,
    designation : 'Founder',
    ratings : 5
},
{
    name: 'Hideaki Suzuki',
    content : `Using labSense, we provide sample images from cameras. Then, our product gets the latest video AI capability in return! SNeT does all the time-consuming tasks of researching AI models, image annotations, model training, and deployment. Embedding custom AI has become so easy for us. Thank you very much!!`,
    designation : '',
    ratings : 5
},
{
    name: 'Amit Singh',
    content : `Flawless project execution. We gave only requirement.They came up with proposal and did complete execution data collection to model training, all was done by them. We received a trained model with sample inference program with more than 91% accuracy. I definitely would like to recommend labsense.ai for their subject matter experience in many business domain and excellence in execution.`,
    designation : 'Product Manager',
    ratings : 5
},
{
    name: 'Akash Chandra',
    content : `I am excited to share my exceptional experience with CloudUnify. As the Founder and Head of Machine Learning at Pinnacle Technologies, I was grappling with the challenge of efficiently managing user access across various cloud platforms for our diverse machine learning projects. CloudUnify came to our rescue with an ingenious solution.Their single point of management dashboard has been a game-changer for us. No longer do we need to navigate through complex technical processes to grant and revoke access for different users across different cloud platforms. With CloudUnify, adding users and managing their access permissions has become an effortless task.I am grateful to CloudUnify for their ingenious solution that has transformed the way we manage cloud access.`,
    designation : '',
    ratings : 5
}
];


const StarRating=(star)=>{
return (
    <>
    {star && [...Array(star.rate)].map((_, index) => (
        <span style={{color : "orange", fontSize: '20px'}}
          key={index+1}
        >
            &#9733;
        </span>
      ))}
    </>
);
};

export const Reviews = ()=>{
    const classes = useStyles();
    return (
        <>
        <Stack className={classes.heroBox}>
            <Typography 
            sx={{textAlign : 'center', color : '#19488e', paddingTop: '30px',
            fontWeight : 'bold'
            }}
             variant='h2' component='h3'>What Client Says</Typography>
            <Carousel className={classes.carousels} 
            indicators={false}
            navButtonsAlwaysInvisible={true}
            animation='slide'
            interval= {3000}
             >
            {allReviews.map((data, index)=>
        <Card key={index+1} variant="elevation" 
        className={index === 3 ? `${classes.cardData} ${classes.customCard}` : classes.cardData}>
            <CardContent className={classes.cardContent}>
                <Typography variant='Secondary'  className={classes.titleHead} gutterBottom>
                    <div className={classes.cardName}>
                        {data.name}
                        <i className={classes.designation}>
                            {data.designation? ` (${data.designation})` : ''}
                        </i>
                    </div>
                    <div className={classes.rate}>
                        <StarRating rate={data.ratings} />
                    </div>
                </Typography>
                <Typography className={classes.content}>
                    {data.content}  
                </Typography>
            </CardContent>
        </Card>
        )}
        </Carousel>
        </Stack>
        </>
    )

}